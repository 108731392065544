const ContentConfigurationEnums = Object.freeze({
  LIMIT_PER_MESSAGE: 160,
  INTEGER: 'integer',
  NUMBER: 'number',
  PREVIEW_MESSAGE: 'Message preview will be displayed here',
  TWO_COLUMNS: 'twoColumns',
  THREE_COLUMNS: 'threeColumns',
  FOUR_COLUMNS: 'fourColumns',
  LOCAL_FILE: 'localFile',
  UPLOAD_TEMPLATE: 'uploadTemplate',
  TOTAL_PAGE_COUNT: '10',
  EDIT_TEMPLATE: 'editTemplate',
  BODY: 'body',
  NAME: 'name',
  HTML: 'text/html',
  DOWNLOAD_NAME: 'template.html',
  DESIGN: 'design',
  HTML_STYLE: 'none',
  WHATSAPP_HEADER: 'HEADER',
  WHATSAPP_BODY: 'BODY',
  WHATSAPP_FOOTER: 'FOOTER',
  WHATSAPP_BUTTONS: 'BUTTONS',
  TCLSMS: 'TCLSMS',
  CONNECTOR: 'Please provide the connector name',
  API_KEY: 'Please provide the API key',
  API_URL: 'Please provide the API URL',
  API_KEY_HIDDEN: '*********',
  WITH_MEDIA: 'With Media',
  WITHOUT_MEDIA: 'Without Media',
  MEDIA: '<<media>>',
  HEADER: 'header',
  WHATSAPP_FLOW_BUTTONS: 'FLOW',
  TEMPLATE_UPDATE: 'updateTemplate',
  SAVE_AS_NEW_TEMPLATE: 'saveNewTemplate',
});

export default ContentConfigurationEnums;
