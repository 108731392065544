const CommonEnums = Object.freeze({
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  PUSH: 'push',
  INAPP: 'inApp',
  MOBILE: 'mobile',
  TATA_SMSC: 'tata_smsc',
  TATA_WHATSAPP: 'tata_whatsapp',
  TATA_SMS: 'tatasmsc',
  BUTTONS: 'buttons',
  BUTTON: 'button',
  WHATSAPP_TATA_COMMUNICATION: 'whatsapp_tata_communications',
  NONE: 'none',
  VALID_DATE: 'Please enter a valid date',
  NO_AGENT: 'NONE',
  TEST_CAMPAIGN: {
    MOBILE_NUMBER: 'mobileNumber',
    UNIQUE_ID: 'unique_id',
    EMAIL_ID: 'emailId',
    CUSTOM_SEGMENT: 'custom_segment',
    SELECTED_OPTIONS: {
      MOBILE_NUMBER: 'phone_number',
      UNIQUE_ID: 'unique_id',
      EMAIL_ID: 'email',
      CUSTOM_SEGMENT: 'custom_segment',
    },
  },
  GENDER: {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
  },
  TYPE_STRING: 'string',
  SUBMIT: 'submit',
  EDIT: 'edit',
  TEST: 'test',
  NEW: 'new',
  SMS_LABEL: 'SMS',
  WHATSAPP_LABEL: 'Whatsapp',
  EMAIL_LABEL: 'Email',
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  ERROR: 'Error',
  AS_SOON_AS_POSSIBLE: 'as_soon_as_possible',
  PERIODIC: 'periodic',
  AT_SPECIFIC_TIME: 'at_specific_time',
  REGISTERED_AUDIENCE: 'registered_audience',
  ANONYMOUS_AUDIENCE: 'anonymous_audience',
  EVENT: 'event',
  ANALYTICS: {
    DELIVERY_RATE: 'delivery_rate',
  },
  PROCESSING: 'processing',
  PENDING: 'pending',
  BODY: 'BODY',

  CHANNEL_NAME_API_MOBILE_PUSH: 'mobile_push',
  PLATFORM_ANDROID: 'android',
  PLATFORM_IOS: 'ios',
  PRIVATE_KEY_FILE: 'private_key_file',
  FCM_SERVER_KEY: 'fcm_server_key',

  APNS_AUTHENTICATION_KEY: 'apns_authentication_key',
  APNS_PROVIDER_CERTIFICATE: 'apns_provider_certificate',
  MENU_DEFAULT: 'menu-default',
  UTC: 'UTC',
  CSAT: {
    LABEL_1: 'Total_responses',
    LABEL_2: 'Satisfaction_score',
    LABEL_3: 'Response_rate',
    RED: 'red',
    ORANGE: 'orange',
    GOLD: 'gold',
    YELLOW: 'yellow',
    GREEN: 'green',
  },
  EN_US: 'en-US',
  SHORT: 'short',
  NUMERIC: 'numeric',
  TWO_DIGIT: '2-digit',
  FORM_GROUP_CUSTOM: {
    IMAGE: 'image',
    TEXTAREA: 'textarea',
    SELECT: 'select',
    RADIO_MULTI: 'radioMulti',
    CHECKBOX_MULTI: 'checkboxMulti',
    FILE: 'file',
    DATE: 'date',
    TAG: 'tag',
    NUMBER: 'number',
  },
  AGENT: 'agent',
  GMT: 'GMT',
  ORDER: {
    ASCENDING: 'ascending',
    DECENDING: 'descending',
  },
  DATE_TIME_FORMAT: 'DD MMMM YYYY hh:mm:A',
});

export default CommonEnums;
