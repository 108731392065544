export const IMPORTUSER_UPLOAD_FILE_SIZE = 1024;
export const CONTROL_GROUP_UPLOAD_FILE_SIZE = 50;
export const FULL_DATE_TIME_FORMAT = 'DD MMMM YYYY h:mm:ss a';
export const IMPORTUSER_UPLOAD_FILE_CHARACTER_LENGTH = 34;
export const COMMA_SEPERATED_FULL_DATE_TIME_FORMAT =
  'ddd, MMM Do YYYY, h:mm:ss a';
export const COMMA_SEPERATED_FULL_DATE_TIME_HH_MM_FORMAT = 'DD MMM YYYY h:mm a';
export const DATE_FORMAT_WITHOUT_TIME = 'YYYY-MM-DD';
export const DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY = 'DD MMMM YYYY';
export const DATE_SLASH_FORMAT_WITHOUT_TIME = 'MM/DD/YYYY';
export const TIME_FORMAT_WITHOUT_DATE = 'LT';
export const DATE_AND_TIME = 'MMM DD, hh:mm a';
export const TIME_ZONE_DATE_FORMAT = 'MM/DD/YYYY h:mm A';
export const CAMPAIGN_INFO_DATE_FORMAT = 'ddd, MMM D, hh:mm:ss A';
export const htmlRegex = /<[^>]*>|&nbsp;|\s+/g;
export const htmlAndConsecutiveRegex = /(\s*<.*?>\s*|&nbsp;)+/g;
export const mentionsRegex = /\[@[\w\s]+\]\(mention:\/\/user\/\d+\/[\w\d%]+\)/g;
export const matchTextRegex = /@[\w\s]+/g;
export const EMAIL_PATTERN = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
export const BITS_TO_BYTES = 1024;
export const COMMAS_REGEX = /(\d+)(\d{3})/;
export const PHONE_NUM_REGEX = /^\+[1-9]\d{1,14}$/;
export const PHONE_NUM_PLUS_REGEX = /^\+[1-9]/;
export const PHONE_NUM_REGEX_WITHOUT_PLUS =
  /^(\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1))[- ]?\d{1,14}$/;
/* eslint-disable no-useless-escape */
export const URL_REGX =
  /(http(s)?:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const ALPHANUMERIC_HYPHEN_UNDERSCORE_REGX = /^[a-zA-Z0-9-_]+$/;
export const FC_DND_REGEX_PATTERN = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const FC_DND_TIME_FORMAT = 'HH:mm';
export const HTML_EMOJI_REGEX = /<\/[^>]*>$/;
export const PASSWORD_GUIDELINE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/;
export const channelTypeList = ['sms', 'email', 'whatsapp'];
export const personalizationRegEx = /\{\{.*?\}\}/;
