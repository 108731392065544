/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const REGISTER_USER_CLEAN = 'REGISTER_USER_CLEAN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_CLEAN = 'FORGOT_PASSWORD_CLEAN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_CLEAN = 'RESET_PASSWORD_CLEAN';
export const VALIDITY_CHECK = 'VALIDITY_CHECK';
export const VALIDITY_CHECK_SUCCESS = 'VALIDITY_CHECK_SUCCESS';
export const VALIDITY_CHECK_ERROR = 'VALIDITY_CHECK_ERROR';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const SINGATURE_UPDATE = 'SINGATURE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const PROFILE_UPDATE_CLEAN = 'PROFILE_UPDATE_CLEAN';
export const PROFILE_AVATAR_DELETE = 'PROFILE_AVATAR_DELETE';
export const PROFILE_AVATAR_DELETE_SUCCESS = 'PROFILE_AVATAR_DELETE_SUCCESS';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const UPDATE_AVAILABILITY_SUCCESS = 'UPDATE_AVAILABILITY_SUCCESS';
export const UPDATE_AVAILABILITY_ERROR = 'UPDATE_AVAILABILITY_ERROR';
export const UPDATE_AVAILABILITY_CLEAN = 'UPDATE_AVAILABILITY_CLEAN';
export const NOTIFICATION_SETTINGS_GET = 'NOTIFICATION_SETTINGS_GET';
export const NOTIFICATION_SETTINGS_GET_SUCCESS =
  'NOTIFICATION_SETTINGS_GET_SUCCESS';
export const NOTIFICATION_SETTINGS_POST = 'NOTIFICATION_SETTINGS_POST';
export const NOTIFICATION_SETTINGS_POST_SUCCESS =
  'NOTIFICATION_SETTINGS_POST_SUCCESS';
export const NOTIFICATION_SETTINGS_POST_ERROR =
  'NOTIFICATION_SETTINGS_POST_ERROR';
export const NOTIFICATION_SETTINGS_POST_CLEAN =
  'NOTIFICATION_SETTINGS_POST_CLEAN';
export const LOGOUT_SUCCESS_OR_FAILURE = 'LOGOUT_SUCCESS_OR_FAILURE';
export const SET_CURRENT_USER_AVAILABILITY = 'SET_CURRENT_USER_AVAILABILITY';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const SET_CONVERSATIONS_CONTACT_LIST = 'SET_CONVERSATIONS_CONTACT_LIST';
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* CONTACTS LIST APP */
export const GET_CONTACTS_LIST = 'GET_CONTACTS_LIST';
export const GET_CONTACTS_LIST_SUCCESS = 'GET_CONTACTS_LIST_SUCCESS';

export const ADD_CONTACT_ITEM = 'ADD_CONTACT_ITEM';
export const ADD_CONTACT_ITEM_SUCCESS = 'ADD_CONTACT_ITEM_SUCCESS';
export const ADD_CONTACT_ITEM_ERROR = 'ADD_CONTACT_ITEM_ERROR';
export const ADD_CONTACT_ITEM_CLEAN = 'ADD_CONTACT_ITEM_CLEAN';

export const DELETE_CONTACT_ITEM = 'DELETE_CONTACT_ITEM';
export const DELETE_CONTACT_ITEM_SUCCESS = 'DELETE_CONTACT_ITEM_SUCCESS';
export const DELETE_CONTACT_ITEM_ERROR = 'DELETE_CONTACT_ITEM_ERROR';
export const DELETE_CONTACT_ITEM_CLEAN = 'DELETE_CONTACT_ITEM_CLEAN';

export const UPDATE_CONTACT_PRESENCE = 'UPDATE_CONTACT_PRESENCE';

export const IMPORT_CONTACT_ITEM = 'IMPORT_CONTACT_ITEM';
export const IMPORT_CONTACT_ITEM_SUCCESS = 'IMPORT_CONTACT_ITEM_SUCCESS';
export const IMPORT_CONTACT_ITEM_ERROR = 'IMPORT_CONTACT_ITEM_ERROR';
export const IMPORT_CONTACT_ITEM_CLEAN = 'IMPORT_CONTACT_ITEM_CLEAN';

export const GET_LABEL_CONTACT_ITEM = 'GET_LABEL_CONTACT_ITEM';
export const GET_LABEL_CONTACT_ITEM_SUCCESS = 'GET_LABEL_CONTACT_ITEM_SUCCESS';

export const DETAILS_CONTACT_ITEM = 'DETAILS_CONTACT_ITEM';
export const DETAILS_CONTACT_ITEM_SUCCESS = 'DETAILS_CONTACT_ITEM_SUCCESS';
export const DETAILS_CONTACT_ITEM_ERROR = 'DETAILS_CONTACT_ITEM_ERROR';
export const DETAILS_CONTACT_ITEM_CLEAN_BEFORE_LOAD =
  'DETAILS_CONTACT_ITEM_CLEAN_BEFORE_LOAD';

export const GET_COMMENTS_CONTACT_ITEM = 'GET_COMMENTS_CONTACT_ITEM';
export const GET_COMMENTS_CONTACT_ITEM_SUCCESS =
  'GET_COMMENTS_CONTACT_ITEM_SUCCESS';

export const ADD_COMMENTS_CONTACT_ITEM = 'ADD_COMMENTS_CONTACT_ITEM';
export const ADD_COMMENTS_CONTACT_ITEM_SUCCESS =
  'ADD_COMMENTS_CONTACT_ITEM_SUCCESS';
export const ADD_COMMENTS_CONTACT_ITEM_ERROR =
  'ADD_COMMENTS_CONTACT_ITEM_ERROR';
export const ADD_COMMENTS_CONTACT_ITEM_CLEAN =
  'ADD_COMMENTS_CONTACT_ITEM_CLEAN';

export const ADD_LABEL_CONTACT_ITEM = 'ADD_LABEL_CONTACT_ITEM';
export const ADD_LABEL_CONTACT_ITEM_SUCCESS = 'ADD_LABEL_CONTACT_ITEM_SUCCESS';
export const ADD_LABEL_CONTACT_ITEM_ERROR = 'ADD_LABEL_CONTACT_ITEM_ERROR';
export const ADD_LABEL_CONTACT_ITEM_CLEAN = 'ADD_LABEL_CONTACT_ITEM_CLEAN';

export const GET_CONVERSATIONS_CONTACT_ITEM = 'GET_CONVERSATIONS_CONTACT_ITEM';
export const GET_CONVERSATIONS_CONTACT_ITEM_SUCCESS =
  'GET_CONVERSATIONS_CONTACT_ITEM_SUCCESS';

export const GET_ATTRIBUTES_CONTACT_ITEM = 'GET_ATTRIBUTES_CONTACT_ITEM';
export const GET_ATTRIBUTES_CONTACT_ITEM_SUCCESS =
  'GET_ATTRIBUTES_CONTACT_ITEM_SUCCESS';

export const ADD_ATTRIBUTES_CONTACT_ITEM = 'ADD_ATTRIBUTES_CONTACT_ITEM';
export const ADD_ATTRIBUTES_CONTACT_ITEM_SUCCESS =
  'ADD_ATTRIBUTES_CONTACT_ITEM_SUCCESS';
export const ADD_ATTRIBUTES_CONTACT_ITEM_ERROR =
  'ADD_ATTRIBUTES_CONTACT_ITEM_ERROR';
export const ADD_ATTRIBUTES_CONTACT_ITEM_CLEAN =
  'ADD_ATTRIBUTES_CONTACT_ITEM_CLEAN';

export const DELETE_ATTRIBUTES_CONTACT_ITEM = 'DELETE_ATTRIBUTES_CONTACT_ITEM';
export const DELETE_ATTRIBUTES_CONTACT_ITEM_SUCCESS =
  'DELETE_ATTRIBUTES_CONTACT_ITEM_SUCCESS';
export const DELETE_ATTRIBUTES_CONTACT_ITEM_ERROR =
  'DELETE_ATTRIBUTES_CONTACT_ITEM_ERROR';
export const DELETE_ATTRIBUTES_CONTACT_ITEM_CLEAN =
  'DELETE_ATTRIBUTES_CONTACT_ITEM_CLEAN';

export const MERGE_CONTACT_ITEM = 'MERGE_CONTACT_ITEM';
export const MERGE_CONTACT_ITEM_SUCCESS = 'MERGE_CONTACT_ITEM_SUCCESS';
export const MERGE_CONTACT_ITEM_ERROR = 'MERGE_CONTACT_ITEM_ERROR';
export const MERGE_CONTACT_ITEM_CLEAN = 'MERGE_CONTACT_ITEM_CLEAN';

export const CONVERSATION_LABEL = 'CONVERSATION_LABEL';
export const CONVERSATION_LABEL_ERROR = 'CONVERSATION_LABEL_ERROR';
export const CONVERSATION_LABEL_SUCCESS = 'CONVERSATION_LABEL_SUCCESS';

export const CONTACT_NEW_MESSAGE = 'CONTACT_NEW_MESSAGE';
export const CONTACT_NEW_MESSAGE_SUCCESS = 'CONTACT_NEW_MESSAGE_SUCCESS';
export const CONTACT_NEW_MESSAGE_ERROR = 'CONTACT_NEW_MESSAGE_ERROR';

export const CONTACT_NEW_CONVERSATION = 'CONTACT_NEW_CONVERSATION';

export const CONTACTABLE_INBOXES = 'CONTACTABLE_INBOXES';
export const CONTACTABLE_INBOXES_ERROR = 'CONTACTABLE_INBOXES_ERROR';
export const CONTACTABLE_INBOXES_SUCCESS = 'CONTACTABLE_INBOXES_SUCCESS';
export const CONTACT_NEW_MESSAGE_CLEAN = 'CONTACT_NEW_MESSAGE_CLEAN';
export const GET_CONVERSATION_SEARCH_LIST = 'GET_CONVERSATION_SEARCH_LIST';
export const GET_CONVERSATION_SEARCH_LIST_SUCCESS =
  'GET_CONVERSATION_SEARCH_LIST_SUCCESS';

export const GET_FILTER_CONTACTS_LIST_SUCCESS =
  'GET_FILTER_CONTACTS_LIST_SUCCESS';
export const GET_FILTER_CONTACTS_LIST_FAILURE =
  'GET_FILTER_CONTACTS_LIST_FAILURE';
export const CONTACT_FILTER_CLEAN_UP = 'CONTACT_FILTER_CLEAN_UP';
export const GET_CONTACTS_FILTERS_VALUE = 'GET_CONTACTS_FILTERS_VALUE';
export const SAVE_CONTACTS_FILTERS = 'SAVE_CONTACTS_FILTERS';
export const SAVE_CONTACTS_FILTERS_SUCCESS = 'SAVE_CONTACTS_FILTERS_SUCCESS';
export const SAVE_CONTACTS_FILTERS_ERROR = 'SAVE_CONTACTS_FILTERS_ERROR';
export const GET_CONTACTS_FILTERS = 'GET_CONTACTS_FILTERS';
export const GET_CONTACTS_FILTERS_SUCCESS = 'GET_CONTACTS_FILTERS_SUCCESS';
export const GET_CONTACTS_FILTERS_ERROR = 'GET_CONTACTS_FILTERS_ERROR';
export const DELETE_CONTACTS_FILTERS = 'DELETE_CONTACTS_FILTERS';
export const DELETE_CONTACTS_FILTERS_SUCCESS =
  'DELETE_CONTACTS_FILTERS_SUCCESS';
export const DELETE_CONTACTS_FILTERS_ERROR = 'DELETE_CONTACTS_FILTERS_ERROR';

/* INBOX APP */
export const GET_INBOX = 'GET_INBOX';
export const GET_INBOX_SUCCESS = 'GET_INBOX_SUCCESS';
export const GET_ALL_INBOXES = 'GET_ALL_INBOXES';
export const GET_ALL_INBOX_SUCCESS = 'GET_ALL_INBOX_SUCCESS';
export const CHAT_GET_CONVERSATIONS_NEWWW = 'CHAT_GET_CONVERSATIONS_NEWWW';
export const CHAT_GET_CONVERSATIONS_SUCCESS_NEWWW =
  'CHAT_GET_CONVERSATIONS_SUCCESS_NEWWW';
export const CHAT_GET_CONVERSATIONS_ERROR_NEWWW =
  'CHAT_GET_CONVERSATIONS_ERROR_NEWWW';
export const CHAT_SEND_CONVERSATION = 'CHAT_SEND_CONVERSATION';
export const CHAT_SEND_CONVERSATION_SUCCESS = 'CHAT_SEND_CONVERSATION_SUCCESS';
export const CHAT_SEND_CONVERSATION_ERROR = 'CHAT_SEND_CONVERSATION_ERROR';
export const CHAT_SEND_CONVERSATION_CLEAN = 'CHAT_SEND_CONVERSATION_CLEAN';
export const CHAT_CHANGE_CONVERSATION_MUTED = 'CHAT_CHANGE_CONVERSATION_MUTED';
export const CHAT_CHANGE_CONVERSATION_MUTED_SUCCESS =
  'CHAT_CHANGE_CONVERSATION_MUTED_SUCCESS';
export const CHAT_CHANGE_CONVERSATION_STATUS =
  'CHAT_CHANGE_CONVERSATION_STATUS';
export const CHAT_CHANGE_CONVERSATION_STATUS_SUCCESS =
  'CHAT_CHANGE_CONVERSATION_STATUS_SUCCESS';
export const CHAT_GET_MESSAGES = 'CHAT_GET_MESSAGES';
export const CHAT_GET_MESSAGES_SUCCESS = 'CHAT_GET_MESSAGES_SUCCESS';
export const CHAT_GET_MESSAGES_ERROR = 'CHAT_GET_MESSAGES_ERROR';
export const WHATSAPP_BLUE_TICK_GET = 'WHATSAPP_BLUE_TICK_GET';
export const WHATSAPP_BLUE_TICK_GET_SUCCESS = 'WHATSAPP_BLUE_TICK_GET_SUCCESS';
export const CHAT_DELETE_MESSAGES = 'CHAT_DELETE_MESSAGES';
export const CHAT_DELETE_MESSAGES_SUCCESS = 'CHAT_DELETE_MESSAGES_SUCCESS';
export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';
export const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT_SEND_MESSAGE_SUCCESS';
export const CHAT_SEND_MESSAGE_ERROR = 'CHAT_SEND_MESSAGE_ERROR';
export const CHAT_SEND_MESSAGE_CLEAN = 'CHAT_SEND_MESSAGE_CLEAN';
export const DELETE_INBOX = 'DELETE_INBOX';
export const DELETE_INBOX_SUCCESS = 'DELETE_INBOX_SUCCESS';
export const DELETE_INBOX_ERROR = 'DELETE_INBOX_ERROR';
export const DELETE_INBOX_CLEAN = 'DELETE_INBOX_CLEAN';
export const CHAT_GET_LASTSEEN_SUCCESS = 'CHAT_GET_LASTSEEN_SUCCESS';
export const ADD_ATTRIBUTES_CONVERSATION_ITEM =
  'ADD_ATTRIBUTES_CONVERSATION_ITEM';
export const ADD_ATTRIBUTES_CONVERSATION_SUCCESS =
  'ADD_ATTRIBUTES_CONVERSATION_SUCCESS';
export const ADD_ATTRIBUTES_CONVERSATION_ERROR =
  'ADD_ATTRIBUTES_CONVERSATION_ERROR';
export const ADD_ATTRIBUTES_CONVERSATION_CLEAN =
  'ADD_ATTRIBUTES_CONVERSATION_CLEAN';
export const CHAT_GET_CONVERSATIONS_CLEAN_NEWWW =
  'CHAT_GET_CONVERSATIONS_CLEAN_NEWWW';
export const GET_CUSTOM_ATTRIBUTES = 'GET_CUSTOM_ATTRIBUTES';
export const GET_CUSTOM_ATTRIBUTES_SUCCESS = 'GET_CUSTOM_ATTRIBUTES_SUCCESS';
export const GET_CUSTOM_ATTRIBUTES_ERROR = 'GET_CUSTOM_ATTRIBUTES_ERROR';
export const GET_CONVERSATION_FILTERS = 'GET_CONVERSATION_FILTERS';
export const ADVANCED_CONVERSATION_FILTERS = 'ADVANCED_CONVERSATION_FILTERS';
export const ADVANCED_CONVERSATION_FILTERS_SUCCESS =
  'ADVANCED_CONVERSATION_FILTERS_SUCCESS';
export const ADVANCED_CONVERSATION_FILTERS_ERROR =
  'ADVANCED_CONVERSATION_FILTERS_ERROR';
export const ADVANCED_CONVERSATION_FILTERS_CLEAN_UP =
  'ADVANCED_CONVERSATION_FILTERS_CLEAN_UP';
export const SAVE_ADVANCED_CONVERSATION_FILTERS =
  'SAVE_ADVANCED_CONVERSATION_FILTERS';
export const SAVE_ADVANCED_CONVERSATION_FILTERS_SUCCESS =
  'SAVE_ADVANCED_CONVERSATION_FILTERS_SUCCESS';
export const SAVE_ADVANCED_CONVERSATION_FILTERS_ERROR =
  'SAVE_ADVANCED_CONVERSATION_FILTERS_ERROR';
export const GET_CUSTOM_FILTERS = 'GET_CUSTOM_FILTERS';
export const MESSAGE_CLEAN = 'MESSAGE_CLEAN';
export const GET_CUSTOM_FILTERS_SUCCESS = 'GET_CUSTOM_FILTERS_SUCCESS';
export const GET_CUSTOM_FILTERS_ERROR = 'GET_CUSTOM_FILTERS_ERROR';
export const DELETE_CUSTOM_FILTERS = 'DELETE_CUSTOM_FILTERS';
export const DELETE_CUSTOM_FILTERS_SUCCESS = 'DELETE_CUSTOM_FILTERS_SUCCESS';
export const DELETE_CUSTOM_FILTERS_ERROR = 'DELETE_CUSTOM_FILTERS_ERROR';
export const ADD_CONVERSATION_SURVEY_RESPONSE =
  'ADD_CONVERSATION_SURVEY_RESPONSE';
export const ADD_CONVERSATION_SURVEY_RESPONSE_SUCCESS =
  'ADD_CONVERSATION_SURVEY_RESPONSE_SUCCESS';
export const GET_CONVERSATION_SURVEY_RESPONSE =
  'GET_CONVERSATION_SURVEY_RESPONSE';
export const GET_CONVERSATION_SURVEY_RESPONSE_SUCCESS =
  'GET_CONVERSATION_SURVEY_RESPONSE_SUCCESS';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const CONVERSATION_CONTACT_DETAILS_UPDATE =
  'CONVERSATION_CONTACT_DETAILS_UPDATE';
export const NEW_MESSAGE_NOTIFICATION = 'NEW_MESSAGE_NOTIFICATION';
export const UPDATE_TYPING_STATUS = 'UPDATE_TYPING_STATUS';
export const UPDATE_CONVERSATION_TEAM = 'UPDATE_CONVERSATION_TEAM';

/* CHANNELS APP */
export const CHANNEL_GET = 'CHANNEL_GET';
export const CHANNEL_GET_SUCCESS = 'CHANNEL_GET_SUCCESS';

export const CHANNEL_ADD = 'CHANNEL_ADD';
export const CHANNEL_ADD_SUCCESS = 'CHANNEL_ADD_SUCCESS';
export const CHANNEL_ADD_ERROR = 'CHANNEL_ADD_ERROR';
export const CHANNEL_ADD_CLEAN = 'CHANNEL_ADD_CLEAN';

export const CHANNEL_UPDATE = 'CHANNEL_UPDATE';
export const CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS';
export const CHANNEL_UPDATE_ERROR = 'CHANNEL_UPDATE_ERROR';
export const CHANNEL_UPDATE_CLEAN = 'CHANNEL_UPDATE_CLEAN';

export const CHANNEL_AVATAR_DELETE = 'CHANNEL_AVATAR_DELETE';
export const CHANNEL_AVATAR_DELETE_SUCCESS = 'CHANNEL_AVATAR_DELETE_SUCCESS';

export const CHANNEL_GET_AGENTS = 'CHANNEL_GET_AGENTS';
export const CHANNEL_GET_AGENTS_SUCCESS = 'CHANNEL_GET_AGENTS_SUCCESS';

export const CHANNEL_ADD_AGENT = 'CHANNEL_ADD_AGENT';
export const CHANNEL_ADD_AGENT_SUCCESS = 'CHANNEL_ADD_AGENT_SUCCESS';
export const CHANNEL_ADD_AGENT_ERROR = 'CHANNEL_ADD_AGENT_ERROR';
export const CHANNEL_ADD_AGENT_CLEAN = 'CHANNEL_ADD_AGENT_CLEAN';

export const ADD_INBOX = 'ADD_INBOX';
export const ADD_INBOX_SUCCESS = 'ADD_INBOX_SUCCESS';
export const ADD_INBOX_ERROR = 'ADD_INBOX_ERROR';
export const ADD_INBOX_CLEAN = 'ADD_INBOX_CLEAN';

export const INBOX_UPDATE = 'INBOX_UPDATE';
export const INBOX_UPDATE_SUCCESS = 'INBOX_UPDATE_SUCCESS';
export const INBOX_UPDATE_ERROR = 'INBOX_UPDATE_ERROR';
export const INBOX_UPDATE_CLEAN = 'INBOX_UPDATE_CLEAN';

export const CONNECTOR_GET = 'CONNECTOR_GET';
export const CONNECTOR_GET_SUCCESS = 'CONNECTOR_GET_SUCCESS';

export const GET_INBOX_LIST = 'GET_INBOX_LIST';
export const GET_INBOX_LIST_SUCCESS = 'GET_INBOX_LIST_SUCCESS';

/* AGENTS APP */

export const AGENTS_GET = 'AGENTS_GET';
export const AGENTS_GET_SUCCESS = 'AGENTS_GET_SUCCESS';

export const AGENTS_DELETE = 'AGENTS_DELETE';
export const AGENTS_DELETE_SUCCESS = 'AGENTS_DELETE_SUCCESS';
export const AGENTS_DELETE_ERROR = 'AGENTS_DELETE_ERROR';
export const AGENTS_DELETE_CLEAN = 'AGENTS_DELETE_CLEAN';

export const AGENTS_ADD = 'AGENTS_ADD';
export const AGENTS_ADD_SUCCESS = 'AGENTS_ADD_SUCCESS';
export const AGENTS_ADD_ERROR = 'AGENTS_ADD_ERROR';
export const AGENTS_ADD_CLEAN = 'AGENTS_ADD_CLEAN';

export const ASSIGN_AGENT_SUCCESS = 'ASSIGN_AGENT_SUCCESS';
export const ASSIGN_AGENT_ERROR = 'ASSIGN_AGENT_ERROR';
export const ASSIGN_AGENT = 'ASSIGN_AGENT';

export const UPDATE_AGENTS_PRESENCE = 'UPDATE_AGENTS_PRESENCE';

/** LABELS APP */
export const LABELS_GET = 'LABELS_GET';
export const LABELS_GET_SUCCESS = 'LABELS_GET_SUCCESS';

export const LABELS_DELETE = 'LABELS_DELETE';
export const LABELS_DELETE_SUCCESS = 'LABELS_DELETE_SUCCESS';
export const LABELS_DELETE_ERROR = 'LABELS_DELETE_ERROR';
export const LABELS_DELETE_CLEAN = 'LABELS_DELETE_CLEAN';

export const LABELS_ADD = 'LABELS_ADD';
export const LABELS_ADD_SUCCESS = 'LABELS_ADD_SUCCESS';
export const LABELS_ADD_ERROR = 'LABELS_ADD_ERROR';
export const LABELS_ADD_CLEAN = 'AGENTS_ADD_CLEAN';

/* CANNEDS APP */

export const CANNEDS_GET = 'CANNEDS_GET';
export const CANNEDS_GET_SUCCESS = 'CANNEDS_GET_SUCCESS';

export const CANNEDS_DELETE = 'CANNEDS_DELETE';
export const CANNEDS_DELETE_SUCCESS = 'CANNEDS_DELETE_SUCCESS';
export const CANNEDS_DELETE_ERROR = 'CANNEDS_DELETE_ERROR';
export const CANNEDS_DELETE_CLEAN = 'CANNEDS_DELETE_CLEAN';

export const CANNEDS_ADD = 'CANNEDS_ADD';
export const CANNEDS_ADD_SUCCESS = 'CANNEDS_ADD_SUCCESS';
export const CANNEDS_ADD_ERROR = 'CANNEDS_ADD_ERROR';
export const CANNEDS_ADD_CLEAN = 'CANNEDS_ADD_CLEAN';

/* ATTRIBUTES APP */

export const ATTRIBUTES_GET = 'ATTRIBUTES_GET';
export const ATTRIBUTES_GET_SUCCESS = 'ATTRIBUTES_GET_SUCCESS';

export const ATTRIBUTES_DELETE = 'ATTRIBUTES_DELETE';
export const ATTRIBUTES_DELETE_SUCCESS = 'ATTRIBUTES_DELETE_SUCCESS';
export const ATTRIBUTES_DELETE_ERROR = 'ATTRIBUTES_DELETE_ERROR';
export const ATTRIBUTES_DELETE_CLEAN = 'ATTRIBUTES_DELETE_CLEAN';

export const ATTRIBUTES_ADD = 'ATTRIBUTES_ADD';
export const ATTRIBUTES_ADD_SUCCESS = 'ATTRIBUTES_ADD_SUCCESS';
export const ATTRIBUTES_ADD_ERROR = 'ATTRIBUTES_ADD_ERROR';
export const ATTRIBUTES_ADD_CLEAN = 'ATTRIBUTES_ADD_CLEAN';

/* TEAMS APP */
export const TEAMS_GET = 'TEAMS_GET';
export const TEAMS_GET_SUCCESS = 'TEAMS_GET_SUCCESS';

export const TEAM_GET = 'TEAM_GET';
export const TEAM_GET_SUCCESS = 'TEAM_GET_SUCCESS';
export const TEAM_GET_CLEAN = 'TEAM_GET_CLEAN';

export const TEAM_ADD = 'TEAM_ADD';
export const TEAM_ADD_SUCCESS = 'TEAM_ADD_SUCCESS';
export const TEAM_ADD_ERROR = 'TEAM_ADD_ERROR';
export const TEAM_ADD_CLEAN = 'TEAM_ADD_CLEAN';

export const TEAM_UPDATE = 'TEAM_UPDATE';
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS';
export const TEAM_UPDATE_ERROR = 'TEAM_UPDATE_ERROR';
export const TEAM_UPDATE_CLEAN = 'TEAM_UPDATE_CLEAN';

export const TEAM_DELETE = 'TEAM_DELETE';
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS';
export const TEAM_DELETE_ERROR = 'TEAM_DELETE_ERROR';
export const TEAM_DELETE_CLEAN = 'TEAM_DELETE_CLEAN';

export const TEAM_GET_AGENTS = 'TEAM_GET_AGENTS';
export const TEAM_GET_AGENTS_SUCCESS = 'TEAM_GET_AGENTS_SUCCESS';

export const TEAM_ADD_AGENT = 'TEAM_ADD_AGENT';
export const TEAM_ADD_AGENT_SUCCESS = 'TEAM_ADD_AGENT_SUCCESS';
export const TEAM_ADD_AGENT_ERROR = 'TEAM_ADD_AGENT_ERROR';
export const TEAM_ADD_AGENT_CLEAN = 'TEAM_ADD_AGENT_CLEAN';

export const ASSIGN_TEAM_SUCCESS = ' ASSIGN_TEAM_SUCCESS';
export const ASSIGN_TEAM_ERROR = ' ASSIGN_TEAM_ERROR';
export const ASSIGN_TEAM = 'ASSIGN_TEAM';

/* INTEGRATIONS APP */

export const INTEGRATIONS_GET = 'INTEGRATIONS_GET';
export const INTEGRATIONS_GET_SUCCESS = 'INTEGRATIONS_GET_SUCCESS';

export const INTEGRATIONS_DELETE = 'INTEGRATIONS_DELETE';
export const INTEGRATIONS_DELETE_SUCCESS = 'INTEGRATIONS_DELETE_SUCCESS';
export const INTEGRATIONS_DELETE_ERROR = 'INTEGRATIONS_DELETE_ERROR';
export const INTEGRATIONS_DELETE_CLEAN = 'INTEGRATIONS_DELETE_CLEAN';

export const INTEGRATIONS_ADD = 'INTEGRATIONS_ADD';
export const INTEGRATIONS_ADD_SUCCESS = 'INTEGRATIONS_ADD_SUCCESS';
export const INTEGRATIONS_ADD_ERROR = 'INTEGRATIONS_ADD_ERROR';
export const INTEGRATIONS_ADD_CLEAN = 'INTEGRATIONS_ADD_CLEAN';

/* APPLICATIONS APP */

export const APPLICATIONS_GET = 'APPLICATIONS_GET';
export const APPLICATIONS_GET_SUCCESS = 'APPLICATIONS_GET_SUCCESS';

export const APPLICATIONS_DELETE = 'APPLICATIONS_DELETE';
export const APPLICATIONS_DELETE_SUCCESS = 'APPLICATIONS_DELETE_SUCCESS';
export const APPLICATIONS_DELETE_ERROR = 'APPLICATIONS_DELETE_ERROR';
export const APPLICATIONS_DELETE_CLEAN = 'APPLICATIONS_DELETE_CLEAN';

export const APPLICATIONS_ADD = 'APPLICATIONS_ADD';
export const APPLICATIONS_ADD_SUCCESS = 'APPLICATIONS_ADD_SUCCESS';
export const APPLICATIONS_ADD_ERROR = 'APPLICATIONS_ADD_ERROR';
export const APPLICATIONS_ADD_CLEAN = 'APPLICATIONS_ADD_CLEAN';
export const APPLICATIONS_GET_INTEGRATIONS = 'APPLICATIONS_GET_INTEGRATIONS';
export const APPLICATIONS_GET_INTEGRATION = 'APPLICATIONS_GET_INTEGRATION';

/* ACCOUNTS APP */

export const ACCOUNT_GET = 'ACCOUNT_GET';
export const ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS';

export const ACCOUNTS_GET = 'ACCOUNTS_GET';
export const ACCOUNTS_GET_SUCCESS = 'ACCOUNTS_GET_SUCCESS';

export const ACCOUNTS_DELETE = 'ACCOUNTS_DELETE';
export const ACCOUNTS_DELETE_SUCCESS = 'ACCOUNTS_DELETE_SUCCESS';
export const ACCOUNTS_DELETE_ERROR = 'ACCOUNTS_DELETE_ERROR';
export const ACCOUNTS_DELETE_CLEAN = 'ACCOUNTS_DELETE_CLEAN';

export const ACCOUNTS_ADD = 'ACCOUNTS_ADD';
export const ACCOUNTS_ADD_SUCCESS = 'ACCOUNTS_ADD_SUCCESS';
export const ACCOUNTS_ADD_ERROR = 'ACCOUNTS_ADD_ERROR';
export const ACCOUNTS_ADD_CLEAN = 'ACCOUNTS_ADD_CLEAN';

/* REPORTS APP */

export const OVERVIEW_GET = 'OVERVIEW_GET';
export const OVERVIEW_GET_SUCCESS = 'OVERVIEW_GET_SUCCESS';

export const OVERVIEW_GET_DETAILS = 'OVERVIEW_GET_DETAILS';
export const OVERVIEW_GET_DETAILS_SUCCESS = 'OVERVIEW_GET_DETAILS_SUCCESS';

export const OVERVIEW_DOWNLOAD = 'OVERVIEW_DOWNLOAD';
export const OVERVIEW_DOWNLOAD_SUCCESS = 'OVERVIEW_DOWNLOAD_SUCCESS';
export const OVERVIEW_DOWNLOAD_ERROR = 'OVERVIEW_DOWNLOAD_ERROR';
export const OVERVIEW_DOWNLOAD_CLEAN = 'OVERVIEW_DOWNLOAD_CLEAN';

export const CSAT_GET = 'CSAT_GET';
export const CSAT_GET_SUCCESS = 'CSAT_GET_SUCCESS';

export const CSAT_GET_DETAILS = 'CSAT_GET_DETAILS';
export const CSAT_GET_DETAILS_SUCCESS = 'CSAT_GET_DETAILS_SUCCESS';

export const USER_REPORT_GET_LIST = 'USER_REPORT_GET_LIST';
export const USER_REPORT_GET_LIST_SUCCESS = 'USER_REPORT_GET_LIST_SUCCESS';

export const GET_SEARCH_USER_REPORT_LIST = 'GET_SEARCH_USER_REPORT_LIST';
export const GET_SEARCH_USER_REPORT_LIST_SUCCESS =
  'GET_SEARCH_USER_REPORT_LIST_SUCCESS';

export const USER_REPORT_UPDATE = 'USER_REPORT_UPDATE';
export const USER_REPORT_UPDATE_SUCCESS = 'USER_REPORT_UPDATE_SUCCESS';
export const USER_REPORT_UPDATE_ERROR = 'USER_REPORT_UPDATE_ERROR';

export const USER_REPORT_DOWNLOAD = 'USER_REPORT_DOWNLOAD';
export const USER_REPORT_DOWNLOAD_SUCCESS = 'USER_REPORT_DOWNLOAD_SUCCESS';
export const USER_REPORT_DOWNLOAD_ERROR = 'USER_REPORT_DOWNLOAD_ERROR';
export const GET_CUSTOM_DATE_REPORT_DATA = 'GET_CUSTOM_DATE_REPORT_DATA';
export const GET_CUSTOM_DATE_REPORT_DATA_CLEAN =
  'GET_CUSTOM_DATE_REPORT_DATA_CLEAN';
export const SET_CUSTOM_DATE_RANGE = 'SET_CUSTOM_DATE_RANGE';

/* CAMPAIGNS APP */

export const CAMPAIGNS_GET = 'CAMPAIGNS_GET';
export const CAMPAIGNS_GET_SUCCESS = 'CAMPAIGNS_GET_SUCCESS';

export const CAMPAIGNS_DELETE = 'CAMPAIGNS_DELETE';
export const CAMPAIGNS_DELETE_SUCCESS = 'CAMPAIGNS_DELETE_SUCCESS';
export const CAMPAIGNS_DELETE_ERROR = 'CAMPAIGNS_DELETE_ERROR';
export const CAMPAIGNS_DELETE_CLEAN = 'CAMPAIGNS_DELETE_CLEAN';

export const CAMPAIGNS_ADD = 'CAMPAIGNS_ADD';
export const CAMPAIGNS_ADD_SUCCESS = 'CAMPAIGNS_ADD_SUCCESS';
export const CAMPAIGNS_ADD_ERROR = 'CAMPAIGNS_ADD_ERROR';
export const CAMPAIGNS_ADD_CLEAN = 'CAMPAIGNS_ADD_CLEAN';

export const CLEAR_CAMPAIGN_ON_TYPE_CHANGE = 'CLEAR_CAMPAIGN_ON_TYPE_CHANGE';

export const CAMPAIGNS_CREATE_TYPE_ADD = 'CAMPAIGNS_CREATE_TYPE_ADD';
export const CAMPAIGNS_CREATE_TYPE_ADD_SUCCESS =
  'CAMPAIGNS_CREATE_TYPE_ADD_SUCCESS';
export const CAMPAIGNS_CREATE_ADD = 'CAMPAIGNS_CREATE_ADD';
export const CAMPAIGNS_CREATE_ADD_SUCCESS = 'CAMPAIGNS_CREATE_ADD_SUCCESS';
export const CAMPAIGNS_CREATE_ADD_ERROR = 'CAMPAIGNS_CREATE_ADD_ERROR';
export const CAMPAIGNS_CREATE_ADD_CLEAN = 'CAMPAIGNS_CREATE_ADD_CLEAN';

export const TEST_SMS_CAMPAIGN = 'TEST_SMS_CAMPAIGN';
export const TEST_SMS_CAMPAIGN_SUCCESS = 'TEST_SMS_CAMPAIGN_SUCCESS';
export const TEST_SMS_CAMPAIGN_ERROR = 'TEST_SMS_CAMPAIGN_ERROR';
export const TEST_SMS_CAMPAIGN_CLEAN = 'TEST_SMS_CAMPAIGN_CLEAN';
export const SET_DEFAULT_BTN = 'SET_DEFAULT_BTN';
export const TEST_EMAIL_CAMPAIGN = 'TEST_EMAIL_CAMPAIGN';
export const TEST_EMAIL_CAMPAIGN_SUCCESS = 'TEST_EMAIL_CAMPAIGN_SUCCESS';
export const TEST_EMAIL_CAMPAIGN_ERROR = 'TEST_EMAIL_CAMPAIGN_ERROR';
export const TEST_EMAIL_CAMPAIGN_CLEAN = 'TEST_EMAIL_CAMPAIGN_CLEAN';
export const TEST_WHATSAPP_CAMPAIGN = 'TEST_WHATSAPP_CAMPAIGN';
export const TEST_WHATSAPP_CAMPAIGN_SUCCESS = 'TEST_WHATSAPP_CAMPAIGN_SUCCESS';
export const TEST_WHATSAPP_CAMPAIGN_ERROR = 'TEST_WHATSAPP_CAMPAIGN_ERROR';
export const TEST_WHATSAPP_CAMPAIGN_CLEAN = 'TEST_WHATSAPP_CAMPAIGN_CLEAN';
export const SET_WHATSAPP_CAMPAIGN_TEMPLATE_CATEGORY =
  'SET_WHATSAPP_CAMPAIGN_TEMPLATE_CATEGORY';

export const SMS_CAMAPAIGNS_TEMPLATES_GET_ALL =
  'SMS_CAMAPAIGNS_TEMPLATES_GET_ALL';
export const SMS_CAMAPAIGNS_TEMPLATES_GET_ALL_SUCCESS =
  'SMS_CAMAPAIGNS_TEMPLATES_GET_ALL_SUCCESS';
export const SMS_CAMAPAIGN_TEMPLATE_DELETE = 'SMS_CAMAPAIGN_TEMPLATE_DELETE';
export const SMS_CAMAPAIGN_TEMPLATE_DELETE_SUCCESS =
  'SMS_CAMAPAIGN_TEMPLATE_DELETE_SUCCESS';
export const SMS_CAMAPAIGN_TEMPLATE_DELETE_ERROR =
  'SMS_CAMAPAIGN_TEMPLATE_DELETE_ERROR';
export const SMS_CAMAPAIGN_TEMPLATE_DELETE_CLEAN =
  'SMS_CAMAPAIGN_TEMPLATE_DELETE_CLEAN';
export const SMS_CAMAPAIGN_TEMPLATE_ADD = 'SMS_CAMAPAIGN_TEMPLATE_ADD';
export const SMS_CAMAPAIGN_TEMPLATE_ADD_SUCCESS =
  'SMS_CAMAPAIGN_TEMPLATE_ADD_SUCCESS';
export const SMS_CAMAPAIGN_TEMPLATE_ADD_ERROR =
  'SMS_CAMAPAIGN_TEMPLATE_ADD_ERROR';
export const SMS_CAMAPAIGN_TEMPLATE_ADD_CLEAN =
  'SMS_CAMAPAIGN_TEMPLATE_ADD_CLEAN';
export const SMS_CAMAPAIGN_TEMPLATE_UPDATE = 'SMS_CAMAPAIGN_TEMPLATE_UPDATE';
export const SMS_CAMAPAIGN_TEMPLATE_UPDATE_SUCCESS =
  'SMS_CAMAPAIGN_TEMPLATE_UPDATE_SUCCESS';
export const SMS_CAMAPAIGN_TEMPLATE_UPDATE_ERROR =
  'SMS_CAMAPAIGN_TEMPLATE_UPDATE_ERROR';
export const SMS_CAMAPAIGN_TEMPLATE_UPDATE_CLEAN =
  'SMS_CAMAPAIGN_TEMPLATE_UPDATE_ERROR';
export const SCHEDULE_SMS_CAMPAIGN = 'SCHEDULE_SMS_CAMPAIGN';
export const SCHEDULE_SMS_CAMPAIGN_SUCCESS = 'SCHEDULE_SMS_CAMPAIGN_SUCCESS';
export const SCHEDULE_SMS_CAMPAIGN_ERROR = 'SCHEDULE_SMS_CAMPAIGN_ERROR';
export const SCHEDULE_SMS_CAMPAIGN_CLEAN = 'SCHEDULE_SMS_CAMPAIGN_CLEAN';
export const SMS_CAMAPAIGN_TAGS = 'SMS_CAMAPAIGN_TAGS';
export const SMS_CAMAPAIGN_TAGS_SUCCESS = 'SMS_CAMAPAIGN_TAGS_SUCCESS';
export const SMS_CAMAPAIGNS_GET_ALL_SENDER_ID =
  'SMS_CAMAPAIGNS_GET_ALL_SENDER_ID';
export const SMS_CAMAPAIGNS_GET_ALL_SENDER_ID_SUCCESS =
  'SMS_CAMAPAIGNS_GET_ALL_SENDER_ID_SUCCESS';
export const SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID =
  'SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID';
export const SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_SUCCESS =
  'SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_SUCCESS';
export const SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_ERROR =
  'SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_ERROR';
export const SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID =
  'SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID';
export const SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_SUCCESS =
  'SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_SUCCESS';
export const SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_ERROR =
  'SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_ERROR';
export const SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_CLEAN =
  'SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_CLEAN';
export const GET_SMS_SEARCH_TEMPLATES = 'GET_SMS_SEARCH_TEMPLATES';
export const GET_SMS_SEARCH_TEMPLATES_SUCCESS =
  'GET_SMS_SEARCH_TEMPLATES_SUCCESS';
export const SMS_CONTENT_GENERATE_TINY_URL = 'SMS_CONTENT_GENERATE_TINY_URL';
export const SMS_CONTENT_GENERATE_TINY_URL_SUCCESS =
  'SMS_CONTENT_GENERATE_TINY_URL_SUCCESS';
export const SMS_CONTENT_GENERATE_TINY_URL_CLEAN =
  'SMS_CONTENT_GENERATE_TINY_URL_CLEAN';
export const SMS_CONTENT_GENERATE_PERSONALIZED_MSG =
  'SMS_CONTENT_GENERATE_PERSONALIZED_MSG';
export const SMS_CONTENT_GENERATE_PERSONALIZED_MSG_SUCCESS =
  'SMS_CONTENT_GENERATE_PERSONALIZED_MSG_SUCCESS';
export const SMS_CONTENT_GENERATE_PERSONALIZED_MSG_RESET =
  'SMS_CONTENT_GENERATE_PERSONALIZED_MSG_RESET';
export const SMS_CAMPAIGN_SAVE_AS_DRAFT = 'SMS_CAMPAIGN_SAVE_AS_DRAFT';
export const SMS_CAMPAIGN_SAVE_AS_DRAFT_SUCCESS =
  'SMS_CAMPAIGN_SAVE_AS_DRAFT_SUCCESS';
export const SMS_CAMPAIGN_SAVE_AS_DRAFT_ERROR =
  'SMS_CAMPAIGN_SAVE_AS_DRAFT_ERROR';
export const SMS_CAMPAIGN_SAVE_AS_DRAFT_CLEAN =
  'SMS_CAMPAIGN_SAVE_AS_DRAFT_CLEAN';
export const SMS_CAMPAIGN_UPDATE_STEP_INDEX = 'SMS_CAMPAIGN_UPDATE_STEP_INDEX';

export const SMS_CAMPAIGN_NAME = 'SMS_CAMPAIGN_NAME';

export const GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST =
  'GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST';
export const GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST_SUCCESS =
  'GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST_SUCCESS';
export const GET_WHATSAPP_CAMPAIGN_DETAILED_TEMPLATES_LIST =
  'GET_WHATSAPP_CAMPAIGN_DETAILED_TEMPLATES_LIST';

export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

export const FETCH_EMAIL_TEMPLATES_REQUEST = 'FETCH_EMAIL_TEMPLATES_REQUEST';
export const FETCH_EMAIL_TEMPLATES_SUCCESS = 'FETCH_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_TEMPLATES_FAILURE = 'FETCH_EMAIL_TEMPLATES_FAILURE';

export const GET_EMAIL_ADDRESS = 'GET_EMAIL_ADDRESS';
export const GET_EMAIL_ADDRESS_SUCCESS = 'GET_EMAIL_ADDRESS_SUCCESS';

export const SET_SUBJECT = 'SET_SUBJECT';
export const SET_PREVIEW_TEXT = 'SET_PREVIEW_TEXT';
export const SET_EMAIL_CONNECTOR = 'SET_EMAIL_CONNECTOR';
export const SET_SENDER_NAME = 'SET_SENDER_NAME';
export const SET_FROM_EMAIL_ADDRESS = 'SET_FROM_EMAIL_ADDRESS';
export const SET_REPLY_TO_EMAIL_ADDRESS = 'SET_REPLY_TO_EMAIL_ADDRESS';
export const SET_CC = 'SET_CC';
export const SET_BCC = 'SET_BCC';
export const SET_FORM_EMAIL_CREATION = 'SET_FORM_EMAIL_CREATION';
export const SET_EMAIL_TARGET_AUDIENCE = 'SET_EMAIL_TARGET_AUDIENCE';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE';
export const ADD_EMAIL_TEMPLATE_ERROR_RESET = 'ADD_EMAIL_TEMPLATE_ERROR_RESET';
export const GET_EMAIL_TEMPLATE_BY_ID = 'GET_EMAIL_TEMPLATE_BY_ID';
export const CLEAN_SELECTED_EMAIL_TEMPLATE = 'CLEAN_SELECTED_EMAIL_TEMPLATE';
export const DELETE_EMAIL_CAMPAIGN_TEMPLATE = 'DELETE_EMAIL_CAMPAIGN_TEMPLATE';
export const DELETE_EMAIL_CAMPAIGN_TEMPLATE_SUCCESS =
  'DELETE_EMAIL_CAMPAIGN_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_CAMPAIGN_TEMPLATE_FAILURE =
  'DELETE_EMAIL_CAMPAIGN_TEMPLATE_FAILURE';
export const DELETE_EMAIL_CAMPAIGN_TEMPLATE_CLEANUP =
  'DELETE_EMAIL_CAMPAIGN_TEMPLATE_CLEANUP';
export const EMAIL_TEMPLATE_ID = 'EMAIL_TEMPLATE_ID';
export const SET_CAMPAIGN_INBOX_ID = 'SET_CAMPAIGN_INBOX_ID';
export const SET_CAMPAIGN_CHANNEL_ID = 'SET_CAMPAIGN_CHANNEL_ID';

/* NOTIFICATIONS LIST APP */
export const GET_NOTIFICATIONS_LIST = 'GET_NOTIFICATIONS_LIST';
export const GET_NOTIFICATIONS_LIST_SUCCESS = 'GET_NOTIFICATIONS_LIST_SUCCESS';
export const GET_NOTIFICATION_UNREAD_COUNT = 'GET_NOTIFICATION_UNREAD_COUNT';
export const GET_NOTIFICATION_UNREAD_COUNT_SUCCESS =
  'GET_NOTIFICATION_UNREAD_COUNT_SUCCESS';
export const UPDATE_NOTIFICATION_READ_STATUS =
  'UPDATE_NOTIFICATION_READ_STATUS';

/* SETTINGS CHANNELS */
export const SETTINGS_EMAIL_CHANNELS_GET = 'SETTINGS_EMAIL_CHANNELS_GET';
export const SETTINGS_EMAIL_CHANNELS_GET_SUCCESS =
  'SETTINGS_EMAIL_CHANNELS_GET_SUCCESS';
export const SETTINGS_EMAIL_CHANNELS_ADD = 'SETTINGS_EMAIL_CHANNELS_ADD';
export const SETTINGS_EMAIL_CHANNELS_ADD_SUCCESS =
  'SETTINGS_EMAIL_CHANNELS_ADD_SUCCESS';
export const SETTINGS_EMAIL_CHANNELS_ADD_ERROR =
  'SETTINGS_EMAIL_CHANNELS_ADD_ERROR';
export const SETTINGS_EMAIL_CHANNELS_ADD_CLEANUP =
  'SETTINGS_EMAIL_CHANNELS_ADD_CLEANUP';
export const SETTINGS_EMAIL_CHANNELS_EDIT = 'SETTINGS_EMAIL_CHANNELS_EDIT';
export const SETTINGS_EMAIL_CHANNELS_EDIT_SUCCESS =
  'SETTINGS_EMAIL_CHANNELS_EDIT_SUCCESS';
export const SETTINGS_EMAIL_CHANNELS_EDIT_ERROR =
  'SETTINGS_EMAIL_CHANNELS_EDIT_ERROR';
export const SETTINGS_EMAIL_CHANNELS_REMOVE = 'SETTINGS_EMAIL_CHANNELS_REMOVE';
export const SETTINGS_EMAIL_CHANNELS_REMOVE_SUCCESS =
  'SETTINGS_EMAIL_CHANNELS_REMOVE_SUCCESS';
export const SETTINGS_EMAIL_CHANNELS_REMOVE_FAIL =
  'SETTINGS_EMAIL_CHANNELS_REMOVE_FAIL';
export const SETTINGS_WHATSAPP_CHANNELS_ADD = 'SETTINGS_WHATSAPP_CHANNELS_ADD';
export const SETTINGS_WHATSAPP_CHANNELS_ADD_SUCCESS =
  'SETTINGS_WHATSAPP_CHANNELS_ADD_SUCCESS';
export const SETTINGS_WHATSAPP_CHANNELS_ADD_ERROR =
  'SETTINGS_WHATSAPP_CHANNELS_ADD_ERROR';
export const SETTINGS_WHATSAPP_CHANNELS_ADD_CLEANUP =
  'SETTINGS_WHATSAPP_CHANNELS_ADD_CLEANUP';
export const SETTINGS_WHATSAPP_CHANNELS_GET = 'SETTINGS_WHATSAPP_CHANNELS_GET';
export const SETTINGS_WHATSAPP_CHANNELS_GET_SUCCESS =
  'SETTINGS_WHATSAPP_CHANNELS_GET_SUCCESS';
export const SETTINGS_WHATSAPP_CHANNELS_REMOVE =
  'SETTINGS_WHATSAPP_CHANNELS_REMOVE';
export const SETTINGS_WHATSAPP_CHANNELS_REMOVE_SUCCESS =
  'SETTINGS_WHATSAPP_CHANNELS_REMOVE_SUCCESS';
export const SETTINGS_WHATSAPP_CHANNELS_REMOVE_ERROR =
  'SETTINGS_WHATSAPP_CHANNELS_REMOVE_ERROR';
export const SETTINGS_WHATSAPP_CHANNELS_EDIT =
  'SETTINGS_WHATSAPP_CHANNELS_EDIT';
export const SETTINGS_WHATSAPP_CHANNELS_EDIT_SUCCESS =
  'SETTINGS_WHATSAPP_CHANNELS_EDIT_SUCCESS';
export const SETTINGS_SMS_CHANNELS_GET = 'SETTINGS_SMS_CHANNELS_GET';
export const SETTINGS_SMS_CHANNELS_GET_SUCCESS =
  'SETTINGS_SMS_CHANNELS_GET_SUCCESS';
export const SETTINGS_SMS_CHANNELS_ADD_SUCCESS =
  'SETTINGS_SMS_CHANNELS_ADD_SUCCESS';
export const SETTINGS_SMS_CHANNELS_ADD_ERROR =
  'SETTINGS_SMS_CHANNELS_ADD_ERROR';
export const SETTINGS_SMS_CHANNELS_ADD_CLEANUP =
  'SETTINGS_SMS_CHANNELS_ADD_CLEANUP';
export const SETTINGS_SMS_CHANNELS_ADD = 'SETTINGS_SMS_CHANNELS_ADD';
export const SETTINGS_SMS_CHANNELS_ADD_REMOVE =
  'SETTINGS_SMS_CHANNELS_ADD_REMOVE';
export const SETTINGS_SMS_CHANNELS_ADD_REMOVE_SUCCESS =
  'SETTINGS_SMS_CHANNELS_ADD_REMOVE_SUCCESS';
export const SETTINGS_SMS_CHANNELS_ADD_REMOVE_FAIL =
  'SETTINGS_SMS_CHANNELS_ADD_REMOVE_FAIL';
export const SETTINGS_SMS_CHANNELS_RESET = 'SETTINGS_SMS_CHANNELS_RESET';
export const SETTINGS_CHANNELS_FC_CONFIG_ADD =
  'SETTINGS_CHANNELS_FC_CONFIG_ADD';
export const SETTINGS_CHANNELS_FC_CONFIG_ADD_SUCCESS =
  'SETTINGS_CHANNELS_FC_CONFIG_ADD_SUCCESS';
export const SETTINGS_CHANNELS_FC_CONFIG_ADD_CLEAN =
  'SETTINGS_CHANNELS_FC_CONFIG_ADD_CLEAN';
export const SETTINGS_CHANNELS_FC_CONFIG_ADD_ERROR =
  'SETTINGS_CHANNELS_FC_CONFIG_ADD_ERROR';
export const SETTINGS_CHANNELS_DND_CONFIG_ADD =
  'SETTINGS_CHANNELS_DND_CONFIG_ADD';
export const SETTINGS_CHANNELS_DND_CONFIG_ADD_SUCCESS =
  'SETTINGS_CHANNELS_DND_CONFIG_ADD_SUCCESS';
export const SETTINGS_CHANNELS_DND_CONFIG_ADD_CLEAN =
  'SETTINGS_CHANNELS_DND_CONFIG_ADD_CLEAN';
export const SETTINGS_CHANNELS_DND_CONFIG_ADD_ERROR =
  'SETTINGS_CHANNELS_DND_CONFIG_ADD_ERROR';
export const SETTINGS_CHANNELS_FC_GET = 'SETTINGS_CHANNELS_FC_GET';
export const SETTINGS_CHANNELS_FC_GET_SUCCESS =
  'SETTINGS_CHANNELS_FC_GET_SUCCESS';
export const SETTINGS_CHANNELS_DND_GET = 'SETTINGS_CHANNELS_DND_GET';
export const SETTINGS_CHANNELS_DND_GET_SUCCESS =
  'SETTINGS_CHANNELS_DND_GET_SUCCESS';
export const SETTINGS_SMS_CHANNELS_EDIT = 'SETTINGS_SMS_CHANNELS_EDIT';
export const SETTINGS_SMS_CHANNELS_EDIT_SUCCESS =
  'SETTINGS_SMS_CHANNELS_EDIT_SUCCESS';
export const COUNTRY_LIST_GET = 'COUNTRY_LIST_GET';
export const COUNTRY_LIST_GET_SUCCESS = 'COUNTRY_LIST_GET_SUCCESS';
export const EMAIL_GENERAL_SETTINGS_ADD = 'EMAIL_GENERAL_SETTINGS_ADD';
export const EMAIL_GENERAL_SETTINGS_ADD_SUCCESS =
  'EMAIL_GENERAL_SETTINGS_ADD_SUCCESS';
export const EMAIL_GENERAL_SETTINGS_ADD_CLEAN =
  'EMAIL_GENERAL_SETTINGS_ADD_CLEAN';
export const EMAIL_GENERAL_SETTINGS_ADD_ERROR =
  'EMAIL_GENERAL_SETTINGS_ADD_ERROR';
export const EMAIL_GENERAL_SETTINGS_GET = 'EMAIL_GENERAL_SETTINGS_GET';
export const EMAIL_GENERAL_SETTINGS_GET_SUCCESS =
  'EMAIL_GENERAL_SETTINGS_GET_SUCCESS';
export const EMAIL_GENERAL_SETTINGS_GET_CLEAN =
  'EMAIL_GENERAL_SETTINGS_GET_CLEAN';

/* IMPORT USERS LIST APP */
export const GET_PAST_IMPORTUSERS_FILE_LIST = 'GET_PAST_IMPORTUSERS_FILE_LIST';
export const GET_PAST_IMPORTUSERS_FILE_LIST_SUCCESS =
  'GET_PAST_IMPORTUSERS_FILE_LIST_SUCCESS';
export const GET_COLUMN_ATTRIBUTE_LIST = ' GET_COLUMN_ATTRIBUTE_LIST';
export const GET_COLUMN_ATTRIBUTE_LIST_SUCCESS =
  ' GET_COLUMN_ATTRIBUTE_LIST_SUCCESS';
export const UPLOAD_IMPORTUSERS_CSV_FILE = 'UPLOAD_IMPORTUSERS_CSV_FILE';
export const UPLOAD_IMPORTUSERS_CSV_FILE_SUCCESS =
  'UPLOAD_IMPORTUSERS_CSV_FILE_SUCCESS';
export const UPLOAD_IMPORTUSERS_CSV_FILE_ERROR =
  'UPLOAD_IMPORTUSERS_CSV_FILE_ERROR';
export const UPLOAD_IMPORTUSERS_CSV_FILE_CLEAN =
  'UPLOAD_IMPORTUSERS_CSV_FILE_CLEAN';
export const GET_IMPORTUSERS_UPLOAD_DATA = 'GET_IMPORTUSERS_UPLOAD_DATA';
export const SET_SELECT_USER = 'SET_SELECT_USER';
export const SET_SELECTED_CHECKBOX = 'SET_SELECTED_CHECKBOX';

/* S3 SFTP */
export const S3SFTP_IMPORT_SOURCE_TYPE_ADD = 'S3SFTP_IMPORT_SOURCE_TYPE_ADD';
export const S3SFTP_IMPORT_SOURCE_TYPE_ADD_SUCCESS =
  'S3SFTP_IMPORT_SOURCE_TYPE_ADD_SUCCESS';
export const DB_CONFIG_SAVE = 'DB_CONFIG_SAVE';
export const DB_CONFIG_SAVE_SUCCESS = 'DB_CONFIG_SAVE_SUCCESS';
export const DB_CONFIG_SAVE_ERROR = 'DB_CONFIG_SAVE_ERROR';
export const DB_MESSAGE_CLEAN = 'DB_MESSAGE_CLEAN';
export const DB_CONFIG_TEST = 'DB_CONFIG_TEST';
export const DB_CONFIG_TEST_SUCCESS = 'DB_CONFIG_TEST_SUCCESS';
export const DB_CONFIG_TEST_ERROR = 'DB_CONFIG_TEST_ERROR';
export const DATABASE_CONFIGURE_FORMAT_ADD_SUCCESS =
  'DATABASE_CONFIGURE_FORMAT_ADD_SUCCESS';
export const DB_CONFIG_SHOW = 'DB_CONFIG_SHOW';
export const DB_CONFIG_SHOW_SUCCESS = 'DB_CONFIG_SHOW_SUCCESS';
export const DB_CONFIG_SHOW_ERROR = 'DB_CONFIG_SHOW_ERROR';
export const DB_CONFIG_EDIT = 'DB_CONFIG_EDIT';
export const DB_CONFIG_EDIT_SUCCESS = 'DB_CONFIG_EDIT_SUCCESS';
export const DB_CONFIG_EDIT_ERROR = 'DB_CONFIG_EDIT_ERROR';
export const DB_CONFIG_CLEAR_TEST_ERROR = 'DB_CONFIG_CLEAR_TEST_ERROR';
export const DB_CONFIG_CLEAR_SHOW_ERROR = 'DB_CONFIG_CLEAR_SHOW_ERROR';
export const GET_DB_CONNECTION_LIST = 'GET_DB_CONNECTION_LIST';
export const GET_DB_CONNECTION_LIST_SUCCESS = 'GET_DB_CONNECTION_LIST_SUCCESS';
export const DB_CONFIG_CLEAR_EDIT_ERROR = 'DB_CONFIG_CLEAR_EDIT_ERROR';
export const GET_DB_CONNECTION_DROPDOWN_LIST =
  'GET_DB_CONNECTION_DROPDOWN_LIST';
export const GET_DB_CONNECTION_DROPDOWN_LIST_SUCCESS =
  'GET_DB_CONNECTION_DROPDOWN_LIST_SUCCESS';
export const GET_TABLE_PREVIEW = 'GET_TABLE_PREVIEW';
export const GET_TABLE_PREVIEW_SUCCESS = 'GET_TABLE_PREVIEW_SUCCESS';
export const GET_TABLE_PREVIEW_ERROR = 'GET_TABLE_PREVIEW_ERROR';
export const CLEAR_TABLE_PREVIEW = 'CLEAR_TABLE_PREVIEW';
export const GET_IMPORT_SCHEDULER_LIST = 'GET_IMPORT_SCHEDULER_LIST';
export const GET_IMPORT_SCHEDULER_LIST_SUCCESS =
  'GET_IMPORT_SCHEDULER_LIST_SUCCESS';
export const GET_IMPORT_SCHEDULER_BY_ID = 'GET_IMPORT_SCHEDULER_BY_ID';
export const GET_IMPORT_SCHEDULER_BY_ID_SUCCESS =
  'GET_IMPORT_SCHEDULER_BY_ID_SUCCESS';
export const ADD_UPDATE_S3SFTP_DB_IMPORT = 'ADD_UPDATE_S3SFTP_DB_IMPORT';
export const ADD_UPDATE_S3SFTP_DB_IMPORT_SUCCESS =
  'ADD_UPDATE_S3SFTP_DB_IMPORT_SUCCESS';
export const ADD_UPDATE_S3SFTP_DB_IMPORT_ERROR =
  'ADD_UPDATE_S3SFTP_DB_IMPORT_ERROR';
export const ADD_UPDATE_S3SFTP_DB_IMPORT_CLEAN_UP =
  'ADD_UPDATE_S3SFTP_DB_IMPORT_CLEAN_UP';
export const ADD_UPDATE_S3SFTP_DB_IMPORT_ERROR_CLEAN_UP =
  'ADD_UPDATE_S3SFTP_DB_IMPORT_ERROR_CLEAN_UP';
export const DB_CONNECTION_DELETE = 'DB_CONNECTION_DELETE';
export const DB_CONNECTION_DELETE_SUCCESS = 'DB_CONNECTION_DELETE_SUCCESS';
export const DB_CONNECTION_DELETE_ERROR = 'DB_CONNECTION_DELETE_ERROR';
export const DB_CONNECTION_DELETE_CLEAN = 'DB_CONNECTION_DELETE_CLEAN';
export const GET_IMPORT_SCHEDULER_DETAIL = 'GET_IMPORT_SCHEDULER_DETAIL';
export const GET_IMPORT_SCHEDULER_DETAIL_SUCCESS =
  'GET_IMPORT_SCHEDULER_DETAIL_SUCCESS';
export const GET_IMPORT_SCHEDULER_DETAIL_ERROR =
  'GET_IMPORT_SCHEDULER_DETAIL_ERROR';
export const CLEAR_GET_IMPORT_SCHEDULER_DETAIL =
  'CLEAR_GET_IMPORT_SCHEDULER_DETAIL';
export const DB_IMPORT_SAVE = 'DB_IMPORT_SAVE';
export const DB_IMPORT_SAVE_SUCCESS = 'DB_IMPORT_SAVE_SUCCESS';
export const DB_IMPORT_SAVE_ERROR = 'DB_IMPORT_SAVE_ERROR';
export const DB_IMPORT_CLEAN_ERROR = 'DB_IMPORT_CLEAN_ERROR';
export const SHOW_IMPORT_LIST = 'SHOW_IMPORT_LIST';
export const SHOW_IMPORT_LIST_SUCCESS = 'SHOW_IMPORT_LIST_SUCCESS';
export const SHOW_IMPORT_LIST_ERROR = 'SHOW_IMPORT_LIST_ERROR ';
export const DB_IMPORT_LIST_CLEAN_ERROR = 'DB_IMPORT_LIST_CLEAN_ERROR ';
export const DELETE_IMPORT = 'DELETE_IMPORT ';
export const DELETE_IMPORT_SUCCESS = 'DELETE_IMPORT_SUCCESS ';
export const DELETE_IMPORT_ERROR = 'DELETE_IMPORT_ERROR ';
export const DELETE_IMPORT_CLEAN_ERROR = 'DELETE_IMPORT_CLEAN_ERROR ';

/* Dashboard Campains */
export const GET_DASHBOARD_CAMPAIGNS_LIST = 'GET_DASHBOARD_CAMPAIGNS_LIST';
export const GET_DASHBOARD_CAMPAIGNS_LIST_SUCCESS =
  'GET_DASHBOARD_CAMPAIGNS_LIST_SUCCESS';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_CAMPAIGN_INFO = 'GET_DASHBOARD_CAMPAIGN_INFO';
export const GET_DASHBOARD_CAMPAIGN_INFO_SUCCESS =
  'GET_DASHBOARD_CAMPAIGN_INFO_SUCCESS';
export const GET_CAMPAIGN_INFO_BY_ID = 'GET_CAMPAIGN_INFO_BY_ID';
export const GET_CAMPAIGN_INFO_BY_ID_SUCCESS =
  'GET_CAMPAIGN_INFO_BY_ID_SUCCESS';
export const GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD =
  'GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD';
export const GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_SUCCESS =
  'GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_SUCCESS';
export const GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_FAILURE =
  'GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_FAILURE';
export const GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_RESET =
  'GET_CAMPAIGNS_DETAILS_EXPORT_DOWNLOAD_RESET';
export const GET_EMAIL_SUBMIT = 'GET_EMAIL_SUBMIT';
export const EMAIL_SUBMIT_SUCCESS = 'EMAIL_SUBMIT_SUCCESS';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const GET_OPTIONS_FOR_FILTERS = 'GET_OPTIONS_FOR_FILTERS';
export const RESCHEDULE_CAMPAIGN = 'RESCHEDULE_CAMPAIGN';
export const CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN';
export const RESCHEDULE_SUCCESS = 'RESCHEDULE_SUCCESS';
export const CANCEL_SUCCESS = 'CANCEL_SUCCESS';
export const PAUSE_CAMPAIGN = 'PAUSE_CAMPAIGN';
export const PAUSE_SUCCESS = 'PAUSE_SUCCESS';
export const RESUME_CAMPAIGN = 'RESUME_CAMPAIGN';
export const RESUME_SUCCESS = 'RESUME_SUCCESS';
export const GENERATE_TINY_URL_REPORT = 'GENERATE_TINY_URL_REPORT';
export const GENERATE_TINY_URL_REPORT_SUCCESS =
  'GENERATE_TINY_URL_REPORT_SUCCESS';
export const GENERATE_TINY_URL_REPORT_ERROR = 'GENERATE_TINY_URL_REPORT_ERROR';
export const GENERATE_TINY_URL_REPORT_CLEAN = 'GENERATE_TINY_URL_REPORT_CLEAN';
export const SET_CUSTOM_DATE_RANGE_DASHBOARD =
  'SET_CUSTOM_DATE_RANGE_DASHBOARD';
export const SET_SINCE_DATE_DASHBOARD = 'SET_SINCE_DATE_DASHBOARD';

/* AB Comparison */
export const GET_AB_DASHBOARD_CAMPAIGN1_LIST =
  'GET_AB_DASHBOARD_CAMPAIGN1_LIST';
export const GET_AB_DASHBOARD_CAMPAIGN2_LIST =
  'GET_AB_DASHBOARD_CAMPAIGN2_LIST';
export const GET_AB_DASHBOARD_CAMPAIGNS_LIST_SUCCESS =
  'GET_AB_DASHBOARD_CAMPAIGNS_LIST_SUCCESS';
export const DASHBOARD_AB_COMPARISON_CLEANUP =
  'DASHBOARD_AB_COMPARISON_CLEANUP';
export const IMPORTUSER_DATA_CLEAN = 'IMPORTUSER_DATA_CLEAN';

/* Segmentation */
export const GET_SEGMENTATION_LIST = 'GET_SEGMENTATION_LIST';
export const GET_SEGMENTATION_LIST_SUCCESS = 'GET_SEGMENTATION_LIST_SUCCESS';
export const GET_CUSTOM_SEGMENT_LIST = 'GET_CUSTOM_SEGMENT_LIST';
export const GET_CUSTOM_SEGMENT_LIST_SUCCESS =
  'GET_CUSTOM_SEGMENT_LIST_SUCCESS';
export const GET_CATEGORY_DROPDOWN_LIST = 'GET_CATEGORY_DROPDOWN_LIST';
export const GET_CATEGORY_DROPDOWN_LIST_SUCCESS =
  'GET_CATEGORY_DROPDOWN_LIST_SUCCESS';

export const GET_USER_EVENTS_LIST = 'GET_USER_EVENTS_LIST';
export const GET_USER_EVENTS_LIST_SUCCESS = 'GET_USER_EVENTS_LIST_SUCCESS';

export const GET_USER_ATTRIBUTES_BASED_ON_USER_EVENTS =
  'GET_USER_ATTRIBUTES_BASED_ON_USER_EVENTS';
export const GET_USER_ATTRIBUTES_BASED_ON_USER_EVENTS_SUCCESS =
  'GET_USER_ATTRIBUTES_BASED_ON_USER_EVENTS_SUCCESS';

export const GET_SEGMENTATION_DETAILS = 'GET_SEGMENTATION_DETAILS';
export const GET_SEGMENTATION_DETAILS_SUCCESS =
  'GET_SEGMENTATION_DETAILS_SUCCESS';

export const SEGMENTATION_ARCHIEVE = 'SEGMENTATION_ARCHIEVE';
export const SEGMENTATION_ARCHIEVE_SUCCESS = 'SEGMENTATION_ARCHIEVE_SUCCESS';

export const SEGMENTATION_UN_ARCHIEVE = 'SEGMENTATION_UN_ARCHIEVE';
export const SEGMENTATION_UN_ARCHIEVE_SUCCESS =
  'SEGMENTATION_UN_ARCHIEVE_SUCCESS';

export const GET_QUERY_LIST = 'GET_QUERY_LIST';
export const GET_QUERY_LIST_SUCCESS = 'GET_QUERY_LIST_SUCCESS';

export const USER_COUNT_FILTER = 'USER_COUNT_FILTER';
export const USER_COUNT_FILTER_SUCCESS = 'USER_COUNT_FILTER_SUCCESS';
export const USER_COUNT_FILTER_RESET = 'USER_COUNT_FILTER_RESET';

export const SEGMENT_CREATE = 'SEGMENT_CREATE';
export const SEGMENT_CREATE_SUCCESS = 'SEGMENT_CREATE_SUCCESS';

export const SEGMENT_UPDATE = 'SEGMENT_UPDATE';
export const SEGMENT_UPDATE_SUCCESS = 'SEGMENT_UPDATE_SUCCESS';
export const SEGMENT_UPDATE_ERROR = 'SEGMENT_UPDATE_ERROR';

export const SEGMENT_USER_EXPORT = 'SEGMENT_USER_EXPORT';
export const SEGMENT_USER_EXPORT_SUCCESS = 'SEGMENT_USER_EXPORT_SUCCESS';

export const SEGMENT_RERUN_QUERY = 'SEGMENT_RERUN_QUERY';
export const SEGMENT_RERUN_QUERY_SUCCESS = 'SEGMENT_RERUN_QUERY_SUCCESS';
export const REFRESH_SEGMENT_DETAILS = 'REFRESH_SEGMENT_DETAILS';
export const REFRESH_SEGMENT_DETAILS_SUCCESS =
  'REFRESH_SEGMENT_DETAILS_SUCCESS';
export const REFRESH_SEGMENT_DETAILS_ERROR = 'REFRESH_SEGMENT_DETAILS_ERROR';
export const REFRESH_SEGMENT_DETAILS_CLEAN = 'REFRESH_SEGMENT_DETAILS_CLEAN';

export const SEGMENT_QUERY_ALERT = 'SEGMENT_QUERY_ALERT';
export const SEGMENT_QUERY_ALERT_SUCCESS = 'SEGMENT_QUERY_ALERT_SUCCESS';

export const SEGMENT_QUERY_DETAILS = 'SEGMENT_QUERY_DETAILS';
export const SEGMENT_QUERY_DETAILS_SUCCESS = 'SEGMENT_QUERY_DETAILS_SUCCESS';

export const GET_USERS_AFTER_SEG_LIST = 'GET_USERS_AFTER_SEG_LIST';
export const GET_USERS_AFTER_SEG_LIST_SUCCESS =
  'GET_USERS_AFTER_SEG_LIST_SUCCESS';
export const SEGMENT_QUERY_CLEANUP = 'SEGMENT_QUERY_CLEANUP';

export const EDIT_FILE_SEGMENT = 'EDIT_FILE_SEGMENT';
export const EDIT_FILE_SEGMENT_SUCCESS = 'EDIT_FILE_SEGMENT_SUCCESS';
export const EDIT_FILE_SEGMENT_ERROR = 'EDIT_FILE_SEGMENT_ERROR';
export const GET_CUSTOM_SEG_LIST = 'GET_CUSTOM_SEG_LIST';
export const GET_CUSTOM_SEG_LIST_SUCCESS = 'GET_CUSTOM_SEG_LIST_SUCCESS';

export const GET_VIEW_SEGMENT_DATA = 'GET_VIEW_SEGMENT_DATA';
export const GET_VIEW_SEGMENT_DATA_SUCCESS = 'GET_VIEW_SEGMENT_DATA_SUCCESS';
export const GET_VIEW_SEGMENT_DATA_CLEAN = 'GET_VIEW_SEGMENT_DATA_CLEAN';

export const GET_EDIT_SEGMENT_DATA = 'GET_EDIT_SEGMENT_DATA';
export const GET_EDIT_SEGMENT_DATA_SUCCESS = 'GET_EDIT_SEGMENT_DATA_SUCCESS';
export const CLEAR_EDIT_SEGMENT_DATA = 'CLEAR_EDIT_SEGMENT_DATA';

export const CREATE_CUSTOM_EVENT = 'CREATE_CUSTOM_EVENT';
export const CREATE_CUSTOM_EVENT_SUCCESS = 'CREATE_CUSTOM_EVENT_SUCCESS';
export const CREATE_CUSTOM_EVENT_ERROR = 'CREATE_CUSTOM_EVENT_ERROR';
export const PERSIST_APPLIED_FILTERS_ALL_SEG =
  'PERSIST_APPLIED_FILTERS_ALL_SEG';

export const SEGMENT_USER_EXPORT_BY_SEGMENT_ID =
  'SEGMENT_USER_EXPORT_BY_SEGMENT_ID';
export const SEGMENT_USER_EXPORT_BY_SEGMENT_ID_SUCCESS =
  'SEGMENT_USER_EXPORT_BY_SEGMENT_ID_SUCCESS';

export const GET_CAMPAIGN_ANALYTICS = 'GET_CAMPAIGN_ANALYTICS';
export const GET_CAMPAIGN_ANALYTICS_SUCCESS = 'GET_CAMPAIGN_ANALYTICS_SUCCESS';

export const USE_THIS_VERSION = 'USE_THIS_VERSION';
export const USE_THIS_VERSION_SUCCESS = 'USE_THIS_VERSION_SUCCESS';
export const USE_THIS_VERSION_ERROR = 'USE_THIS_VERSION_ERROR';

export const GET_CAMPAIGN_CHANNEL_AND_TYPE_LIST =
  'GET_CAMPAIGN_CHANNEL_AND_TYPE_LIST';
export const GET_CAMPAIGN_CHANNEL_AND_TYPE_LIST_SUCCESS =
  'GET_CAMPAIGN_CHANNEL_AND_TYPE_LIST_SUCCESS';

export const UPDATE_SEGMENT_REACHABLE_USERS = 'UPDATE_SEGMENT_REACHABLE_USERS';

/* Flows*/
export const FLOWS_NAME = 'FLOWS_NAME';
export const ADD_FLOW_DETAILS = 'ADD_FLOW_DETAILS';

/* Mobile Push */
export const GET_MOBILE_PUSH_CONFIGURATION = 'GET_MOBILE_PUSH_CONFIGURATION';
export const GET_MOBILE_PUSH_CONFIGURATION_SUCCESS =
  'GET_MOBILE_PUSH_CONFIGURATION_SUCCESS';
export const ADD_MOBILE_PUSH_CONFIGURATION = 'ADD_MOBILE_PUSH_CONFIGURATION';
export const ADD_MOBILE_PUSH_CONFIGURATION_SUCCESS =
  'ADD_MOBILE_PUSH_CONFIGURATION_SUCCESS';
export const MOBILE_PUSH_CONFIGURATION_CLEANUP =
  'MOBILE_PUSH_CONFIGURATION_CLEANUP';
export const MOBILE_PUSH_CONFIGURATION_ADD_ERROR =
  'MOBILE_PUSH_CONFIGURATION_ADD_ERROR';

/* Control Groups */
export const GET_CONTROL_GROUPS = 'GET_CONTROL_GROUPS';
export const GET_CONTROL_GROUPS_SUCCESS = 'GET_CONTROL_GROUPS_SUCCESS';
export const CREATE_CONTROL_GROUPS = 'CREATE_CONTROL_GROUPS';
export const CREATE_CONTROL_GROUPS_SUCCESS = 'CREATE_CONTROL_GROUPS_SUCCESS';
export const CREATE_CONTROL_GROUPS_FAILURE = 'CREATE_CONTROL_GROUPS_FAILURE';
export const CREATE_CONTROL_GROUPS_SUCCESS_RESET =
  'CREATE_CONTROL_GROUPS_SUCCESS_RESET';
export const DELETE_CONTROL_GROUPS = 'DELETE_CONTROL_GROUPS';
export const DELETE_CONTROL_GROUPS_SUCCESS = 'DELETE_CONTROL_GROUPS_SUCCESS';
export const DELETE_CONTROL_GROUPS_SUCCESS_RESET =
  'DELETE_CONTROL_GROUPS_SUCCESS_RESET';
export const DOWNLOAD_CONTROL_GROUPS = 'DOWNLOAD_CONTROL_GROUPS';
export const DOWNLOAD_CONTROL_GROUPS_SUCCESS =
  'DOWNLOAD_CONTROL_GROUPS_SUCCESS';
export const DOWNLOAD_CONTROL_GROUPS_RESET = 'DOWNLOAD_CONTROL_GROUPS_RESET';
export const ON_CONVERSATION_CREATED_EVENT = 'ON_CONVERSATION_CREATED_EVENT';
export const FETCH_CONVERSATION_STATS = 'FETCH_CONVERSATION_STATS';
export const FETCH_CONVERSATION_STATS_META = 'FETCH_CONVERSATION_STATS_META';
export const FETCH_CONVERSATION_STATS_META_SUCCESS =
  'FETCH_CONVERSATION_STATS_META_SUCCESS';

/* Websocket Conversation Events */
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const SET_CONTACTS = 'SET_CONTACTS';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';
export const DELETE_FROM_CONVERSATION = 'DELETE_FROM_CONVERSATION';

/* Recent Events  */
export const GET_RECENT_EVENTS = 'GET_RECENT_EVENTS';
export const GET_RECENT_EVENTS_SUCCESS = 'GET_RECENT_EVENTS_SUCCESS';

export const NEW_CONVERSATIONS_LIST = 'NEW_CONVERSATION_LIST';
export const NEW_CONTACTS_LIST = 'NEW_CONTACT_LIST';
export const GET_NOTIFICATION_UPDATED_UNREAD_COUNT =
  'GET_NOTIFICATION_UPDATED_UNREAD_COUNT';
export const ADD_USER_TYPING_TO_CONVERSATION =
  'ADD_USER_TYPING_TO_CONVERSATION';
export const REMOVE_USER_TYPING_FROM_CONVERSATION =
  'REMOVE_USER_TYPING_FROM_CONVERSATION';
export const UPDATE_CONVERSATION_CONTACT = 'UPDATE_CONVERSATION_CONTACT';
export const GET_EXPORT_CATEGORY_DROPDOWN_LIST_SUCCESS =
  'GET_EXPORT_CATEGORY_DROPDOWN_LIST_SUCCESS';
